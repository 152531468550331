<div class="container-fluid">
  <div class="portal-updates">
    <div class="row">
      <div class="col-md-12 rightSide">
        <!-- <button class="btn btn-primary" (click)="registerFAQ()">Add New Video FAQ Entry</button> -->
      </div>
    </div>
    <div class="row">
      <!-- <div class="mat-elevation-z8">
        <table style="table-layout: fixed" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Position</th>
            <td mat-cell *matCellDef="let element">{{ element.position }}</td>
          </ng-container>

          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef>Question</th>
            <td mat-cell *matCellDef="let element">{{ element.question }}</td>
          </ng-container>

          <ng-container matColumnDef="videoUrl">
            <th mat-header-cell *matHeaderCellDef>Video Url</th>
            <td mat-cell *matCellDef="let element">{{ element.description | trim: 25 }}</td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Action</th>

            <td mat-cell *matCellDef="let element">
              <button class="btn btn-primary" (click)="editFaqEntry(element.id)">Edit Or View</button>&ensp;
              <button class="btn btn-primary" (click)="confirmModal(element.id, element.status)">
                {{ element.status ? 'Unpublish' : 'Publish' }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="4">No data found</td>
          </tr>
        </table>
  
        <mat-paginator
          [pageSizeOptions]="[50]"
          (page)="handlePageEvent($event)"
          [length]="totalPages"
          showFirstLastButtons
          aria-label="Select page"
        >
        </mat-paginator>
      </div> -->
    </div>
  </div>
</div>

<ng-template #conformPublishUnpublish let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to {{ status ? 'publish' : 'unpublish' }} the video faq entry?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="publishUnpublishEntry()" [disabled]="isLoading">Yes</button>
  </div>
</ng-template>
