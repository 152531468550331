import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqType, Status } from '@app/auth/typings/common-constants';
import { FaqCategory } from '@app/shell/shell';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodicElement } from '../model/faq.';
import { FaqService } from '../service/faq.service';
import { RegisterTextFaqComponent } from './register-text-faq/register-text-faq.component';
import { UpdateTextFaqComponent } from './update-text-faq/update-text-faq.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('conformPublishUnpublish') conformPublishUnpublish!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] = ['position', 'question', 'description', 'id'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  pageNumber: number = 0;
  pageSize: number = 50;
  faqContantList: any;
  isLoading = false;
  totalElements: number = 0;
  totalPages: number = 0;
  pageEvent!: PageEvent;
  entryId!: number;
  status!: boolean;
  faqCategory: string = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];

  constructor(
    private router: Router,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      } else if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_ONE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  ngOnInit(): void {
    this.ELEMENT_DATA = [];
    this.faqEntryList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  faqEntryList() {
    this.isLoading = true;
    // this.ELEMENT_DATA = [];
    this.totalPages = 0;
    // this.faqService
    //   .getPaginationFAQEntryList(
    //     this.pageNumber,
    //     this.pageSize,
    //     Status[Status.ALL],
    //     FaqType[FaqType.TEXT],
    //     this.faqCategory
    //   )
    //   .subscribe(
    //     (res) => {
    //       if (res.data.totalPages > 0) {
    //         res.data.content.forEach((element: any) => {
    //           this.ELEMENT_DATA.push({
    //             position: element.position,
    //             question: element.question,
    //             // category: element.category,
    //             description: element.answerList[0],
    //             id: element.id,
    //             status: element.status,
    //           });
    //         });
    //       }
    //       this.faqContantList = res.data;
    //       this.totalPages = this.faqContantList.totalElements; // this.ELEMENT_DATA.length;
    //       this.dataSource.data = this.ELEMENT_DATA;
    //       // this.paginatedCalculation();
    //       this.isLoading = false;
    //     },
    //     (error) => {
    //       this.isLoading = false;
    //       console.log(error);
    //     }
    //   );
  }

  handlePageEvent(e: PageEvent) {
    // this.ELEMENT_DATA = [];
    // this.totalPages = [];
    this.pageEvent = e;
    // this.totalElements = e.length;
    // this.totalPages = e.length;
    // console.log(e);
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.faqEntryList();
  }

  paginatedCalculation() {
    // if (this.totalElements > this.pageSize * this.pageNumber) {
    //   this.pageNumber += 1;
    //   this.totalPages.push(this.pageSize * this.pageNumber);
    //   console.log(this.totalPages);
    //   this.faqEntryList();
    // }
  }

  registerFAQ() {
    const modalRef = this.modalService.open(RegisterTextFaqComponent, {
      windowClass: 'register-faq-entry',
      size: 'md',
    });
    modalRef.componentInstance.isAddFaqEntry.subscribe((message: boolean) => {
      if (message) {
        this.modalService.dismissAll();
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  editFaqEntry(entryId: number) {
    const modalRef = this.modalService.open(UpdateTextFaqComponent, { windowClass: 'update-faq-entry', size: 'md' });
    modalRef.componentInstance.entryId = entryId;
    modalRef.componentInstance.isUpdateFaqEntry.subscribe((message: boolean) => {
      if (message) {
        this.modalService.dismissAll();
        this.ELEMENT_DATA = [];
        this.faqEntryList();
      }
    });
  }

  confirmModal(entryId: number, status: boolean) {
    this.entryId = entryId;
    this.status = status ? false : true;
    this.modalService.open(this.conformPublishUnpublish, {
      windowClass: 'confirm-publish-unpublish',
      size: 'md',
      centered: true,
    });
  }

  publishUnpublishEntry() {
    this.isLoading = true;
    this.faqService.ChangeEntryStatus(this.entryId, this.status).subscribe(
      (res) => {
        if (res) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.faqEntryList();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
