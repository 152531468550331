export class ChangePasswordCommand {
  userEmail: string = '';
  oldPassword: string = '';
  newPassword: string = '';
}

export enum FaqCategory {
  WATCHO_EXCLUSIVE,
  WATCHO_ONE,
}
