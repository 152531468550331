export class AppConstants {
  static AUTH_HEADER_KEY = 'Authorization';
  static USER_DETAILS = 'user';
  static APP_TITLE = 'Watcho Flexi Plan';
}

export enum Status {
  ALL,
  PUBLISHED,
  UNPUBLISHED,
}

export enum FaqType {
  TEXT,
  VIDEO,
}

export enum RoleName {
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
}
