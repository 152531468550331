import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaqCategory } from '@app/shell/shell';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterFAQEntry } from '../../model/faq.';
import { FaqService } from '../../service/faq.service';

@Component({
  selector: 'app-register-text-faq',
  templateUrl: './register-text-faq.component.html',
  styleUrls: ['./register-text-faq.component.scss'],
})
export class RegisterTextFaqComponent implements OnInit {
  @Output() isAddFaqEntry: EventEmitter<boolean> = new EventEmitter(false);
  version: string | null = environment.version;
  error: string | undefined;
  registerFAQForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  faqCategory!: string;

  constructor(
    private formBuilder: FormBuilder,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
      } else if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_ONE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
      }
    });
  }

  ngOnInit(): void {}

  registerNewFAQ() {
    this.isLoading = true;
    let registerFAQEntry = new RegisterFAQEntry();
    registerFAQEntry.question = this.registerFAQForm.value.question;
    // registerFAQEntry.answerList = [this.htmlContent];
    registerFAQEntry.type = 'TEXT';
    registerFAQEntry.position = +this.registerFAQForm.value.position;
    registerFAQEntry.videoUrl = '';
    registerFAQEntry.category = this.faqCategory;
    if (this.registerFAQForm.valid) {
      // this.faqService.registerFAQEntry(registerFAQEntry).subscribe(
      //   (res) => {
      //     if (res) {
      //       this.isAddFaqEntry.next(true);
      //     }
      //     this.isLoading = false;
      //   },
      //   (error) => {
      //     this.isLoading = false;
      //   }
      // );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.registerFAQForm = this.formBuilder.group({
      question: ['', Validators.required],
      description: ['', Validators.required],
      position: ['', Validators.required],
      // category: ['', Validators.required],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
