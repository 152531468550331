import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { merge } from 'rxjs';
import { FaqCategory } from '../shell';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Output() isClickedMenu = new EventEmitter<boolean>(true);

  public navItems: any[] = [
    {
      title: 'Home',
      redirectTo: '/home',
      icon: 'home',
    },
    // {
    //   title: 'DIY',
    //   redirectTo: '/diy',
    //   icon: 'ballot',
    // },
    {
      title: 'Users',
      redirectTo: '/accounts',
      icon: 'supervisor_account',
    },
    // {
    //   title: 'Projects',
    //   redirectTo: '/projects',
    //   icon: 'business_center',
    // },
  ];
  public currentRoute: string = '';
  roleName!: string;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    // this.roleName = this.authenticationService.getRoleNameByToken();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.isClickedMenu.next(false);
      }
    });
  }

  watchoExclusive() {
    this.router.navigate(['/home'], { queryParams: { faqCategory: FaqCategory[FaqCategory.WATCHO_EXCLUSIVE] } });
  }

  watchoOne() {
    this.router.navigate(['/home'], { queryParams: { faqCategory: FaqCategory[FaqCategory.WATCHO_ONE] } });
  }
}
