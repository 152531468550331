import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FaqCategory } from '@app/shell/shell';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateFAQEntry } from '../../model/faq.';
import { FaqService } from '../../service/faq.service';

@Component({
  selector: 'app-update-video-faq',
  templateUrl: './update-video-faq.component.html',
  styleUrls: ['./update-video-faq.component.scss'],
})
export class UpdateVideoFaqComponent implements OnInit {
  @Input() entryId: number = 0;
  @Output() isUpdateFaqEntry: EventEmitter<boolean> = new EventEmitter(false);

  version: string | null = environment.version;
  error: string | undefined;
  updateFAQForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  faqCategory!: string;

  constructor(
    private formBuilder: FormBuilder,
    private faqService: FaqService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_EXCLUSIVE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_EXCLUSIVE];
      } else if (queryParams.faqCategory === FaqCategory[FaqCategory.WATCHO_ONE]) {
        this.faqCategory = FaqCategory[FaqCategory.WATCHO_ONE];
      }
    });
  }

  ngOnInit(): void {
    this.getFaqEntryDetail();
  }

  getFaqEntryDetail() {
    this.isLoading = true;
    this.faqService.getFAQEntryDetail(this.entryId).subscribe(
      (res) => {
        if (res.data) {
          this.updateFAQForm = this.formBuilder.group({
            question: [res.data.question, Validators.required],
            type: [res.data.type, Validators.required],
            position: [res.data.position, Validators.required],
            videoUrl: [res.data.videoUrl],
            category: [res.data.category],
          });
          if (res.data.answerList.length > 0) {
            res.data.answerList.forEach((ele: any) => {
              // this.htmlContent += ele + ' ';
            });
          }
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  updateFaqEntry() {
    this.isLoading = true;
    let updateFAQEntry = new UpdateFAQEntry();
    updateFAQEntry.question = this.updateFAQForm.value.question;
    updateFAQEntry.answerList = [];
    updateFAQEntry.type = 'VIDEO';
    updateFAQEntry.position = +this.updateFAQForm.value.position;
    updateFAQEntry.videoUrl = this.updateFAQForm.value.videoUrl;
    updateFAQEntry.category = this.faqCategory;
    if (this.updateFAQForm.valid) {
      // this.faqService.updateFAQEntry(this.entryId, updateFAQEntry).subscribe(
      //   (res) => {
      //     if (res) {
      //       this.isUpdateFaqEntry.emit(true);
      //     }
      //     this.isLoading = false;
      //   },
      //   (error) => {
      //     this.isLoading = false;
      //   }
      // );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.updateFAQForm = this.formBuilder.group({
      question: ['', Validators.required],
      position: ['', Validators.required],
      videoUrl: ['', Validators.required],
      // category: [''],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
