import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './loader/loader.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { StringTrimPipe } from '@app/shell/string-trim-pipe';

@NgModule({
  imports: [TranslateModule, CommonModule, MatCardModule, MatTabsModule],
  declarations: [LoaderComponent, StringTrimPipe],
  exports: [LoaderComponent, MatCardModule, MatTabsModule, StringTrimPipe],
})
export class SharedModule {}
