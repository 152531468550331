<nav class="main-navbar">
  <div class="logo"></div>
  <div class="main-navbar__content">
    <ul class="main-navbar__content__wrapper">
      <li class="main-navbar__content__wrapper--item mb-2" *ngFor="let item of navItems">
        <div
          class="nav-item"
          [ngClass]="{ active: currentRoute === item.redirectTo }"
          [routerLink]="[item.redirectTo]"
          routerLinkActive="active"
        >
          <span class="material-icons nav-icon">
            {{ item.icon }}
          </span>
          <div class="nav-title">{{ item.title }}</div>
        </div>
        <!-- <div
          class="nav-item"
          [ngClass]="{ active: currentRoute === item.redirectTo }"
          routerLinkActive="active"
          *ngIf="item.title === 'FAQ(s)'"
        >
          <span class="material-icons nav-icon">
            {{ item.icon }}
          </span>
          <div class="nav-title">
            {{ item.title }}
            <div class="sub-side-nav">
              <ul>
                <li>
                  <div (click)="watchoExclusive()">Watcho Exclusive</div>
                </li>
                <li>
                  <div (click)="watchoOne()">Watcho Offers & Plans</div>
                </li>
              </ul>
            </div>
          </div>
          <span *ngIf="item.title === 'FAQ(s)'" class="material-icons next-icon"> navigate_next </span>
        </div> -->
      </li>
    </ul>
  </div>
</nav>
